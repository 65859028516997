var dev_url = config.APPSTORE.dev_url+'/';
var id = window.dev_id?window.dev_id:'';

var cn_language_mapping = {
    'fullName':'企业名已存在',
    'nickname':'昵称已存在',
    'shortName':'简称已存在',
    'idNumber':'证件号已存在',
    'bankAccount':'账户已存在',
    'contactIdNumber':'联系人已存在',
    'businessNumber':'营业执照已存在',
    'bankPermitNo':'开户许可证已存在',
    'taxRegistrationNo':'税务登记证已存在',
    'company_idNumber':'证件号已存在',
    'contactEmail':'邮箱已存在',
    'telphone':'电话已存在',
    'contactMobile':'电话已存在',
    'individualEmail':'邮箱已存在',
    'checkMobile':'电话已存在',
};

var interface_url_mapping = {
    'fullName':'check-company-full-name',
    'nickname':'check-nickname',
    'shortName':'check-nickname',
    'idNumber':'check-idnumber',
    'bankAccount':'check-bank-account',
    'contactIdNumber':'check-contact-idnumber',
    'businessNumber':'check-business-license-no',
    'bankPermitNo':'check-bank-permit-no',
    'taxRegistrationNo':'check-tax-registration-no',
    'company_idNumber':'check-idnumber',
    'contactEmail':'check-email',
    'telphone':'check-company-telephone',
    'contactMobile':'check-company-contact-mobile',
    'individualEmail':'check-email',
    'checkMobile':'check-individual-mobile',
}


var interface_param_mapping = {
    'fullName':'fullName',
    'nickname':'nickname',
    'shortName':'nickname',
    'idNumber':'idnumber',
    'bankAccount':'bankAccuont',
    'contactIdNumber':'idnumber',
    'businessNumber':'businessLicenseNo',
    'bankPermitNo':'bankPermitNo',
    'taxRegistrationNo':'taxRegistrationNo',
    'company_idNumber':'idnumber',
    'contactEmail':'email',
    'telphone':'telephone',
    'contactMobile':'contactMobile',
    'individualEmail':'email',
    'checkMobile':'mobile',
}

function validate_notempty(name,special_validate,isCompany) {
    var $this = $('#' + name);
    var $itemBox = $('#' + name + '_box');
    var $itemLabel = $('#' + name + '_label');
    if ($this.val() == '') {
        $itemBox.addClass('has-error');
        $itemLabel.html('不能为空');
        //isValidate[name] = false;
        if (isCompany) {
            c_isValidate[name] = false;
        }
        else {
            i_isValidate[name] = false;
        }
    } else {
        var _pass = true;
        if(special_validate){
            _pass = special_validate($this,$itemBox,$itemLabel)
        }

        if (_pass) {
            $itemBox.removeClass('has-error');
            $itemLabel.html('*');
            if (isCompany) {
                c_isValidate[name] = true;
            }
            else {
                i_isValidate[name] = true;
            }
        }
    }
}

function validate_ajax(name,special_validate,isCompany)
{
    var $this = $('#'+name);
    var $itemBox = $('#'+name+'_box');
    var $itemLabel = $('#'+name+'_label');
    if($this.val()=='')
    {
        $itemBox.addClass('has-error');
        $itemLabel.html('不能为空');
        //isValidate[name] = false;
        if(isCompany){
            c_isValidate[name] = false;
        }
        else{
            i_isValidate[name] = false;
        }

    }
    else
    {
        var _pass = true;
        if(special_validate){
            _pass = special_validate($this,$itemBox,$itemLabel)
        }
        if(_pass){
            $.ajax({
                type: "GET",
                url: (dev_url+interface_url_mapping[name]),
                data:interface_param_mapping[name]+"="+$('#'+name).val()+"&developerId="+id+"&accessToken="+window.app_token,
                success: function(json)
                {
                    if(json.resultCode== 1){
                        $itemBox.addClass('has-error');
                        $itemLabel.html(cn_language_mapping[name]);
                        //isValidate[name] = false;
                        if(isCompany){
                            c_isValidate[name] = false;
                        }
                        else{
                            i_isValidate[name] = false;
                        }
                        return false;
                    }
                    else{
                        $itemBox.removeClass('has-error');
                        $itemLabel.html('*');
                        //isValidate[name] = true;
                        if(isCompany){
                            c_isValidate[name] = true;
                        }
                        else{
                            i_isValidate[name] = true;
                        }
                        return true;
                    }
                }
            });
        }
    }

}


var i_isValidate = [];
var c_isValidate = [];


$(function(){

    // $.ajax({
    //     type: "GET",
    //     url: config.AJAX_URL.getAppTokenAjax_url,
    //     data: "",
    //     success: function(msg){
    //         window.app_token = msg;
    //     }
    // });

    //-----------------开发者类别：个人-----------------------

    //真实姓名
    $('#username').blur(function(){
        validate_notempty('username', null, false);
    });

    //昵称
    $('#nickname').blur(function(){
        validate_ajax('nickname', null, false);
    });

    //移动电话
    $('#checkMobile').blur(function(){
        validate_ajax('checkMobile',function(_this,_itemBox,_itemLabel){
            if(_this.val()==''){
                //$('#checkMobile_box').addClass('has-error');
                //$('#checkMobile_label').html('请填写移动电话');
                ////isValidate['checkMobile'] = false;
                //i_isValidate['checkMobile'] = false;
            }else{
                var reg= /^[1][34578]\d{9}$/;
                if(!reg.test(_this.val())){
                    _itemBox.addClass('has-error');
                    _itemLabel.html('错误的移动电话');
                    //isValidate['checkMobile'] = false;
                    i_isValidate['checkMobile'] = false;
                    return false;
                }
            }
            return true;
        },false);
    });

    //电子邮箱
    $('#individualEmail').blur(function(){
        validate_ajax('individualEmail',function(_this,_itemBox,_itemLabel){
            if(_this.val()==''){
                //$('#individualEmail_box').addClass('has-error');
                //$('#individualEmail_label').html('请填写电子邮箱');
                ////isValidate['individualEmail'] = false;
                //i_isValidate['individualEmail'] = false;
            }else{
                var reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
                if(!reg.test(_this.val())){
                    _itemBox.addClass('has-error');
                    _itemLabel.html('错误的电子邮箱');
                    //isValidate['individualEmail'] = false;
                    i_isValidate['individualEmail'] = false;
                    return false;
                }
            }
            return true;
        },false);

    });

    //有效证件号码
    $('#idNumber').blur(function(){
        validate_ajax('idNumber',function(_this,_itemBox,_itemLabel){
            if($('#idType').val() == 1){
                if(IdCardValidate(_this.val())==false){
                    _itemBox.addClass('has-error');
                    _itemLabel.html('错误的证件号码');
                    //isValidate['idNumber'] = false;
                    i_isValidate['idNumber'] = false;
                    return false;
                }
            }
            return true;
        },false);
    });

    //通信地址
    $('#address').blur(function(){
        validate_notempty('address',null, false);
        //if($('#address').val()==''){
        //    $('#address_box').addClass('has-error');
        //    $('#address_label').html('请填写通信地址');
        //    //isValidate['address'] = false;
        //    i_isValidate['address'] = false;
        //}else{
        //    $('#address_box').removeClass('has-error');
        //    $('#address_label').html('*');
        //    //isValidate['address'] = true;
        //    i_isValidate['address'] = true;
        //}
    });

    //邮编
    $('#zipcode').blur(function(){

        validate_notempty('zipcode',function(_this,_itemBox,_itemLabel){
            var reg=/^\d{6}$/;
            if(!reg.test(_this.val())){
                _itemBox.addClass('has-error');
                _itemLabel.html('错误的邮编');
                //isValidate['zipcode'] = false;
                i_isValidate['zipcode'] = false;
                return false;
            }
            return true;
        },false);

        //if($('#zipcode').val()==''){
        //    $('#zipcode_box').addClass('has-error');
        //    $('#zipcode_label').html('请填写邮编');
        //    //isValidate['zipcode'] = false;
        //    i_isValidate['zipcode'] = false;
        //}else{
        //    var reg=/^\d{6}$/;
        //    if(!reg.test($('#zipcode').val())){
        //        $('#zipcode_box').addClass('has-error');
        //        $('#zipcode_label').html('错误的邮编');
        //        //isValidate['zipcode'] = false;
        //        i_isValidate['zipcode'] = false;
        //    }else{
        //        $('#zipcode_box').removeClass('has-error');
        //        $('#zipcode_label').html('*');
        //        //isValidate['zipcode'] = true;
        //        i_isValidate['zipcode'] = true;
        //    }
        //}
    });

    //银行账户--申请个人
    $('#bankAccount').blur(function(){
        // 允许空
        if($('#bankAccount').val()==''){
            $('#bankAccount_box').removeClass('has-error');
            $('#bankAccount_label').html('');
            i_isValidate['bankAccount'] = true;
            return;
        }

        validate_notempty('bankAccount',function(_this,_itemBox,_itemLabel){
            var reg=/^[0-9]*$/;
            if(!reg.test(_this.val())){
                _itemBox.addClass('has-error');
                _itemLabel.html('错误的银行账户');
                i_isValidate['bankAccount'] = false;
                return false;
            }
            return true;
        },false);

        //if($('#bankAccount').val()==''){
        //    $('#bankAccount_box').removeClass('has-error');
        //    $('#bankAccount_label').html('');
        //    //isValidate['bankAccount'] = false;
        //}else{
        //    var reg=/^[0-9]*$/;
        //    if(!reg.test($('#bankAccount').val())){
        //        $('#bankAccount_box').addClass('has-error');
        //        $('#bankAccount_label').html('错误的银行账户');
        //        //isValidate['bankAccount'] = false;
        //    }else{
        //        $('#bankAccount_box').removeClass('has-error');
        //        $('#bankAccount_label').html('');
        //        //isValidate['bankAccount'] = true;
        //    }
        //}
    });


    $('#individual_form').submit(function(){
        $('input',this).blur();
        for(key in i_isValidate){
            if(!i_isValidate[key]){
                return false;
            }
        }
    });


    //-----------------开发者类别：企业-----------------------

    //全称
    $('#fullName').blur(function(){
        validate_ajax('fullName',function(_this,_itemBox,_itemLabel){
            if(_this.val().length > 30){
                _itemBox.addClass('has-error');
                _itemLabel.html('字符最多30个字符');
                c_isValidate['fullName'] = false;
                return false;
            }
            return true;
        },true);
        //var name='fullName';
        //var $this = $(this);
        //var $itemBox = $('#'+name+'_box');
        //var $itemLabel = $('#'+name+'_label');
        //validate_ajax('fullName',function(){
        //    if($this.val()=='')
        //    {
        //        $itemBox.addClass('has-error');
        //        $itemLabel.html('不能为空');
        //        c_isValidate[name] = false;
        //    }else if($this.val().length > 30){
        //        $itemBox.addClass('has-error');
        //        $itemLabel.html('字符最多30个字符');
        //        c_isValidate[name] = false;
        //    }else{
        //        $itemBox.removeClass('has-error');
        //        $itemLabel.html('*');
        //        return true;
        //    }
        //},true);


    });

    //简称
    $('#shortName').blur(function(){
        validate_ajax('shortName',null,true);
    });

    //电子邮箱
    $('#contactEmail').blur(function(){

        validate_ajax('contactEmail',function(_this,_itemBox,_itemLabel){
            var reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
            if(!reg.test(_this.val())){
                _itemBox.addClass('has-error');
                _itemLabel.html('错误的电子邮箱');
                //isValidate['contactEmail'] = false;
                c_isValidate['contactEmail'] = false;
                return false;
            }
            return true;
        },true);

        //validate_ajax('contactEmail',function(_this,_itemBox,_itemLabel){
        //    if($('#contactEmail').val()==''){
        //        $('#contactEmail_box').addClass('has-error');
        //        $('#contactEmail_label').html('请填写电子邮箱');
        //        //isValidate['contactEmail'] = false;
        //        c_isValidate['contactEmail'] = false;
        //    }else{
        //        var reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        //        if(!reg.test($('#contactEmail').val())){
        //            $('#contactEmail_box').addClass('has-error');
        //            $('#contactEmail_label').html('错误的电子邮箱');
        //            //isValidate['contactEmail'] = false;
        //            c_isValidate['contactEmail'] = false;
        //        }else{
        //            $('#contactEmail_box').removeClass('has-error');
        //            $('#contactEmail_label').html('*');
        //            //isValidate['contactEmail'] = true;
        //            //c_isValidate['contactEmail'] = true;
        //            return true;
        //        }
        //    }
        //},true);
    });

    //企业地址
    $('#company_address').blur(function(){
        validate_notempty('company_address', null, true);
    });

    //企业邮编
    $('#company_zipCode').blur(function(){

        // 允许空
        if($('#company_zipCode').val()==''){
            $('#company_zipCode_box').removeClass('has-error');
            $('#company_zipCode_label').html('');
            c_isValidate['company_zipCode'] = true;
            return;
        }

        validate_notempty('company_zipCode',function(_this,_itemBox,_itemLabel){
            var reg=/^\d{6}$/;
            if(!reg.test($('#company_zipCode').val())){
                $('#company_zipCode_box').addClass('has-error');
                $('#company_zipCode_label').html('错误的邮编');
                //isValidate['company_zipCode'] = false;
                c_isValidate['company_zipCode'] = false;
                return false;
            }
            return true;
        },true);

        //if($('#company_zipCode').val() != ''){
        //    var reg=/^\d{6}$/;
        //    if(!reg.test($('#company_zipCode').val())){
        //        $('#company_zipCode_box').addClass('has-error');
        //        $('#company_zipCode_label').html('错误的邮编');
        //        //isValidate['company_zipCode'] = false;
        //        c_isValidate['company_zipCode'] = false;
        //    }else{
        //        $('#company_zipCode_box').removeClass('has-error');
        //        $('#company_zipCode_label').html('');
        //        //isValidate['company_zipCode'] = true;
        //        c_isValidate['company_zipCode'] = true;
        //    }
        //}
    });

    //银行账户--申请企业
   /* $('#bankAccounts').blur(function(){
        if($('#bankAccounts').val()==''){
            $('#bankAccounts_box').addClass('has-error');
            $('#bankAccounts_label').html('请填写银行账户');
            //isValidate['bankAccounts'] = false;
            c_isValidate['bankAccounts'] = false;
        }else{
            var reg=/^[0-9]*$/;
            if(!reg.test($('#bankAccounts').val())){
                $('#bankAccounts_box').addClass('has-error');
                $('#bankAccounts_label').html('错误的银行账户');
                //isValidate['bankAccounts'] = false;
                c_isValidate['bankAccounts'] = false;
            }else{
                $('#bankAccounts_box').removeClass('has-error');
                $('#bankAccounts_label').html('*');
                //isValidate['bankAccounts'] = true;
                c_isValidate['bankAccounts'] = true;
            }
        }
    });*/

    //联系电话
    $('#telphone').blur(function(){

        validate_ajax('telphone',function(_this,_itemBox,_itemLabel){
            var reg=/(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}/;
            if(!reg.test(_this.val())){
                _itemBox.addClass('has-error');
                _itemLabel.html('错误的联系电话');
                //isValidate['telphone'] = false;
                c_isValidate['telphone'] = false;
                return false;
            }
            return true;
        },true);

        //validate_ajax('telphone',function(){
        //    if($('#telphone').val() != ''){
        //        var reg=/(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}/;
        //        if(!reg.test($('#telphone').val())){
        //            $('#telphone_box').addClass('has-error');
        //            $('#telphone_label').html('错误的联系电话');
        //            //isValidate['telphone'] = false;
        //            c_isValidate['telphone'] = false;
        //        }else{
        //            $('#telphone_box').removeClass('has-error');
        //            $('#telphone_label').html('');
        //            //isValidate['telphone'] = true;
        //            //c_isValidate['telphone'] = false;
        //            return true;
        //        }
        //    }
        //},true);
    });
    //
    ////传真
    //$('#fax').blur(function(){
    //    if($('#fax').val()==''){
    //        $('#fax_box').addClass('has-error');
    //        $('#fax_label').html('请填写传真');
    //        //isValidate['fax'] = false;
    //        c_isValidate['fax'] = false;
    //    }else{
    //        //var reg=/^[0-9]*$/;
    //        var reg=/(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}/;
    //        if(!reg.test($('#fax').val())){
    //            $('#fax_box').addClass('has-error');
    //            $('#fax_label').html('错误的传真');
    //            //isValidate['fax'] = false;
    //            c_isValidate['fax'] = false;
    //        }else{
    //            $('#fax_box').removeClass('has-error');
    //            $('#fax_label').html('*');
    //            //isValidate['fax'] = true;
    //            c_isValidate['fax'] = true;
    //        }
    //    }
    //});

    //联系人
    $('#contactName').blur(function(){

        validate_notempty('contactName',function(_this,_itemBox,_itemLabel){
            if(_this.val().length < 2 || _this.val().length >64) {
                _itemBox.addClass('has-error')
                _itemLabel.html('字符在2-64个字符')
                c_isValidate['contactName'] = false;
                return false;
            }
            return true;
        },true);

        ////联系人姓名
        //if($('#contactName').val()==''){
        //    $('#contactName_box').addClass('has-error')
        //    $('#contactName_label').html('请填写联系人姓名')
        //    c_isValidate[name] = false;
        //}else if($('#contactName').val().length < 2 || $('#contactName').val().length >64) {
        //    $('#contactName_box').addClass('has-error')
        //    $('#contactName_label').html('字符在2-64个字符')
        //    c_isValidate[name] = false;
        //}else {
        //    $('#contactName_box').removeClass('has-error')
        //    $('#contactName_label').html('*')
        //}
    });

    //联系人移动电话
    $('#contactMobile').blur(function(){

        validate_ajax('contactMobile',function(_this,_itemBox,_itemLabel){
            var reg= /^[1][34578]\d{9}$/;
            if(!reg.test(_this.val())){
                _itemBox.addClass('has-error');
                _itemLabel.html('错误的联系移动电话');
                //isValidate['contactMobile'] = false;
                c_isValidate['contactMobile'] = false;
                return false;
            }
            return true;
        },true);

        //validate_ajax('contactMobile',function(){
        //    if($('#contactMobile').val()==''){
        //        $('#contactMobile_box').addClass('has-error');
        //        $('#contactMobile_label').html('请填写联系移动电话');
        //        //isValidate['contactMobile'] = false;
        //        c_isValidate['contactMobile'] = false;
        //    }else{
        //        var reg= /^[1][358]\d{9}$/;
        //        if(!reg.test($('#contactMobile').val())){
        //            $('#contactMobile_box').addClass('has-error');
        //            $('#contactMobile_label').html('错误的联系移动电话');
        //            //isValidate['contactMobile'] = false;
        //            c_isValidate['contactMobile'] = false;
        //        }else{
        //            $('#contactMobile_box').removeClass('has-error');
        //            $('#contactMobile_label').html('*');
        //            //isValidate['contactMobile'] = true;
        //            //c_isValidate['contactMobile'] = true;
        //            return true;
        //        }
        //    }
        //},true);
    });

    //联系人邮编
    $('#contact_zipcode').blur(function(){

        // 允许空
        if($('#contact_zipcode').val()==''){
            $('#contact_zipcode_box').removeClass('has-error');
            $('#contact_zipcode_label').html('');
            c_isValidate['contact_zipcode'] = true;
            return;
        }

        validate_notempty('contact_zipcode',function(_this,_itemBox,_itemLabel){
            var reg=/^\d{6}$/;
            if(!reg.test(_this.val())){
                _itemBox.addClass('has-error');
                _itemLabel.html('错误的联系人邮编');
                //isValidate['contact_zipcode'] = false;
                c_isValidate['contact_zipcode'] = false;
                return false;
            }
            return true;
        },true);

        //if($('#contact_zipcode').val() != ''){
        //    var reg=/^\d{6}$/;
        //    if(!reg.test($('#contact_zipcode').val())){
        //        $('#contact_zipcode_box').addClass('has-error');
        //        $('#contact_zipcode_label').html('错误的联系人邮编');
        //        //isValidate['contact_zipcode'] = false;
        //        c_isValidate['contact_zipcode'] = false;
        //    }else{
        //        $('#contact_zipcode_box').removeClass('has-error');
        //        $('#contact_zipcode_label').html('');
        //        //isValidate['contact_zipcode'] = true;
        //        c_isValidate['contact_zipcode'] = true;
        //    }
        //}
    });

    //联系人证件号码
    $('#contactIdNumber').blur(function(){
        validate_ajax('contactIdNumber',function(_this,_itemBox,_itemLabel){
            if($('#contactIdType').val() == 1){
                if(IdCardValidate(_this.val())==false){
                    _itemBox.addClass('has-error');
                    _itemLabel.html('错误的证件号码');
                    //isValidate['contactIdNumber'] = false;
                    c_isValidate['contactIdNumber'] = false;
                    return false;
                }
            }
            return true;
        },true);
    });

    ////法人证件号码
    //$('#company_idNumber').blur(function(){
    //  validate_ajax('company_idNumber',function(_this,_itemBox,_itemLabel){
    //      if($('#company_idType').val() == 1){
    //          if(IdCardValidate(_this.val())==false){
    //              _itemBox.addClass('has-error');
    //              _itemLabel.html('错误的证件号码');
    //              c_isValidate['company_idNumber'] = false;
    //              return false;
    //          }
    //      }
    //      return true;
    //  },true);
    //});

    //工商营业执照号
    $('#businessNumber').blur(function(){
        validate_ajax('businessNumber',function(_this,_itemBox,_itemLabel){
            var reg=/^[0-9a-zA-Z]*$/;
            if(!reg.test(_this.val())){
                _itemBox.addClass('has-error');
                _itemLabel.html('错误的执照号');
                c_isValidate['businessNumber'] = false;
                return false;
            }
            return true;
        },true);

        //if($('#businessNumber').val()==''){
        //    $('#businessNumber_box').addClass('has-error');
        //    $('#businessNumber_label').html('不能为空');
        //    c_isValidate['businessNumber'] = false;
        //}else{
        //    validate_ajax('businessNumber',function($this,$itemBox,$itemLabel){
        //        var reg=/^[0-9a-zA-Z]*$/;
        //        if(!reg.test($('#businessNumber').val())){
        //            $('#businessNumber_box').addClass('has-error');
        //            $('#businessNumber_label').html('错误的执照号');
        //            c_isValidate['businessNumber'] = false;
        //        }else{
        //            $('#businessNumber_box').addClass('has-error');
        //            $('#businessNumber_label').html('*');
        //            return true;
        //        }
        //    },true);
        //}
    });

    //税务登记证号
   //$('#taxRegistrationNo').blur(function(){
   //     if($('#taxRegistrationNo').val()==''){
   //        // $('#taxRegistrationNo_box').addClass('has-error');
   //         $('#taxRegistrationNo_box').removeClass('has-error');
   //         $('#taxRegistrationNo_label').html('');
   //         //isValidate['taxRegistrationNo'] = false;
   //     }else{
   //         var reg=/^[0-9]*$/;
   //         if(!reg.test($('#taxRegistrationNo').val())){
   //             $('#taxRegistrationNo_box').addClass('has-error');
   //             $('#taxRegistrationNo_label').html('错误的登记证号');
   //             //isValidate['taxRegistrationNo'] = false;
   //         }else{
   //             $('#taxRegistrationNo_box').removeClass('has-error');
   //             $('#taxRegistrationNo_label').html('');
   //             //isValidate['taxRegistrationNo'] = true;
   //         }
   //     }
   // });

    //银行开户许可证号
    //$('#bankPermitNo').blur(function(){
    //    if($('#bankPermitNo').val()==''){
    //        $('#taxRegistrationNo_box').addClass('has-error');
    //        $('#taxRegistrationNo_label').html('请填写许可证号');
    //        //isValidate['bankPermitNo'] = false;
    //        c_isValidate['bankPermitNo'] = false;
    //    }else{
    //        var reg=/^[0-9]*$/;
    //        if(!reg.test($('#bankPermitNo').val())){
    //            $('#bankPermitNo_box').addClass('has-error');
    //            $('#bankPermitNo_label').html('错误的许可证号');
    //            //isValidate['bankPermitNo'] = false;
    //            c_isValidate['bankPermitNo'] = false;
    //        }else{
    //            $('#bankPermitNo_box').removeClass('has-error');
    //            $('#bankPermitNo_label').html('*');
    //            //isValidate['bankPermitNo'] = true;
    //            c_isValidate['bankPermitNo'] = true;
    //        }
    //    }
    //});
    //工商营业执照
/*  $('#businessNumber').blur(function(){
        if($(this).val()!=''){
            validate_ajax('businessNumber');
        }
    });


    //税务登记证号
    $('#taxRegistrationNo').blur(function(){
        if($(this).val()!=''){
            validate_ajax('taxRegistrationNo');
        }
    });

    //银行开户许可证
    $('#bankPermitNo').blur(function(){
        if($(this).val()!=''){
            validate_ajax('bankPermitNo');
        }
    });*/


    $('#idCopyFile').click(function(){
        if($('#idNumber').val() == ''){
            $('#idNumber_box').addClass('has-error');
            $('#idNumber_label').html('证件号码不能为空');
            return false;
        }
    });

    $('#accountCopyFile').click(function(){
        if($('#bankAccount').val() == ''){
            $('#bankAccount_box').addClass('has-error');
            $('#bankAccount_label').html('如果上传银行账号，号码不能为空');
            return false;
        }
    });

    //$('#company_idCopyFile').click(function(){
    //  if($('#company_idNumber').val() == ''){
    //      $('#company_idNumber_box').addClass('has-error');
    //      $('#company_idNumber_label').html('证件号码不能为空');
    //      return false;
    //  }
    //});

    $('#businessLicenseCopyFile').click(function(){
        if($('#businessNumber').val() == ''){
            $('#businessNumber_box').addClass('has-error');
            $('#businessNumber_label').html('如果上传营业执照，号码不能为空');
            return false;
        }
    });



    //$('#taxRegistrationCopyFile').click(function(){
    //  if($('#taxRegistrationNo').val() == ''){
    //      $('#taxRegistrationNo_box').addClass('has-error');
    //      $('#taxRegistrationNo_label').html('如果上传税务登记证，号码不能为空');
    //      return false;
    //  }
    //});
/*
    $('#taxRegistrationNo').blur(function(){
        if($(this).val() != ''){
            $('#taxRegistrationNo_box').removeClass('has-error');
            $('#taxRegistrationNo_label').html('');
        }
    });
*/
    //$('#bankPermitCopyFile').click(function(){
    //  if($('#bankPermitNo').val() == ''){
    //      $('#bankPermitNo_box').addClass('has-error');
    //      $('#bankPermitNo_label').html('证件号码不能为空');
    //      return false;
    //  }
    //});

    //$('#bankPermitNo').blur(function(){
    //  if($(this).val() != ''){
    //      $('#bankPermitNo_box').removeClass('has-error');
    //      $('#bankPermitNo_label').html('');
    //  }
    //});

    $('#company_form').submit(function(){
        /*$('#fullName').blur();
         $('#contactName').blur();
         $('#shortName').blur();
         $('#contactEmail').blur();
         $('#company_zipCode').blur();
         $('#telphone').blur();
         $('#fax').blur();
         $('#contactMobile').blur();
         $('#contact_zipcode').blur();
         $('#contactIdNumber').blur();*/
debugger;
        $('input',this).blur();
        //$('#company_form input').blur();

        for(key in c_isValidate){
            if(!c_isValidate[key]){
                return false;
            }
        }
    });

});









//
var Wi = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1 ];    // 加权因子
var ValideCode = [ 1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2 ];            // 身份证验证位值.10代表X
function IdCardValidate(idCard) {
    idCard = trim(idCard.replace(/ /g, ""));               //去掉字符串头尾空格
    if (idCard.length == 15) {
        return isValidityBrithBy15IdCard(idCard);       //进行15位身份证的验证
    } else if (idCard.length == 18) {
        var a_idCard = idCard.split("");                // 得到身份证数组
        if(isValidityBrithBy18IdCard(idCard)&&isTrueValidateCodeBy18IdCard(a_idCard)){   //进行18位身份证的基本验证和第18位的验证
            return true;
        }else {
            return false;
        }
    } else {
        return false;
    }
}
/**
 * 判断身份证号码为18位时最后的验证位是否正确
 * @param a_idCard 身份证号码数组
 * @return
 */
function isTrueValidateCodeBy18IdCard(a_idCard) {
    var sum = 0;                             // 声明加权求和变量
    if (a_idCard[17].toLowerCase() == 'x') {
        a_idCard[17] = 10;                    // 将最后位为x的验证码替换为10方便后续操作
    }
    for ( var i = 0; i < 17; i++) {
        sum += Wi[i] * a_idCard[i];            // 加权求和
    }
    valCodePosition = sum % 11;                // 得到验证码所位置
    if (a_idCard[17] == ValideCode[valCodePosition]) {
        return true;
    } else {
        return false;
    }
}
/**
  * 验证18位数身份证号码中的生日是否是有效生日
  * @param idCard 18位书身份证字符串
  * @return
  */
function isValidityBrithBy18IdCard(idCard18){
    var year =  idCard18.substring(6,10);
    var month = idCard18.substring(10,12);
    var day = idCard18.substring(12,14);
    var temp_date = new Date(year,parseFloat(month)-1,parseFloat(day));
    // 这里用getFullYear()获取年份，避免千年虫问题
    if(temp_date.getFullYear()!=parseFloat(year)
        ||temp_date.getMonth()!=parseFloat(month)-1
        ||temp_date.getDate()!=parseFloat(day)){
        return false;
    }else{
        return true;
    }
}
  /**
   * 验证15位数身份证号码中的生日是否是有效生日
   * @param idCard15 15位书身份证字符串
   * @return
   */
function isValidityBrithBy15IdCard(idCard15){
    var year =  idCard15.substring(6,8);
    var month = idCard15.substring(8,10);
    var day = idCard15.substring(10,12);
    var temp_date = new Date(year,parseFloat(month)-1,parseFloat(day));
    // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
    if(temp_date.getYear()!=parseFloat(year)
        ||temp_date.getMonth()!=parseFloat(month)-1
        ||temp_date.getDate()!=parseFloat(day)){
        return false;
    }else{
        return true;
    }
}
//去掉字符串头尾空格
function trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 通过身份证判断是男是女
 * @param idCard 15/18位身份证号码
 * @return 'female'-女、'male'-男
 */
 function maleOrFemalByIdCard(idCard){
    idCard = trim(idCard.replace(/ /g, ""));// 对身份证号码做处理。包括字符间有空格。
    if(idCard.length==15){
        if(idCard.substring(14,15)%2==0){
            return 'female';
        }else{
            return 'male';
        }
    }else if(idCard.length ==18){
        if(idCard.substring(14,17)%2==0){
            return 'female';
        }else{
            return 'male';
        }
    }else{
        return null;
    }
}


//用户
function user_box(obj,id){
    $('.info_title').css('background','#eeeeee')
    for(i=1;i<=2;i++){
        $('#userinfo_'+i).css('display','none')
    }
    $(obj).css('background','#b3e6e1')
    $('#userinfo_'+id).css('display','block')

}



